"use client";

import { Button } from '@/components/ui/button';
import { MdExpandMore } from 'react-icons/md';

export default function Home() {


    const handleScroll = () => {
      window.scrollTo({
        top: window.innerHeight - 96,
        behavior: "smooth"
      });
    };
  

  return (
  <div> 
    <div className="flex items-center justify-center">
    <div className="h-screen w-[1400px] relative">
      <div className='justify-end flex'>
        <img src="startingpage.png" alt="hero-bg" className="h-screen object-cover justify-end" />
      </div>

      <div className='grid grid-cols-2 content-center h-screen absolute top-0 left-0'>
        <div className="flex flex-col text-justify">
          <p className="text-company-green sm:text-2xl text-xl font-bold uppercase">Welcome to coyned!</p>
          <p className="text-white sm:text-7xl text-5xl font-bold">THE WORLDS</p>
          <p className="text-white sm:text-7xl text-5xl font-bold">FIRST MUSIC</p>
          <p className="text-company-green sm:text-7xl text-5xl font-bold">EXCHANGE </p>
          <p className="text-white text-md opacity-60 mt-8">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>

          <a href='/register'><Button className="mt-8 bg-company-green w-52 h-12 rounded-full text-company-gray">Get Started</Button></a>
        </div>

        <div></div>
      </div>
      
      <div onClick={handleScroll} className='absolute inset-x-0 justify-center text-white flex bottom-24'>
        <MdExpandMore size={60} className="transition-transform transform hover:scale-125"/>
      </div>
    </div>

    

        
    </div>
    <div className='w-full bg-company-green h-44'>
      <div className='max-w-[1400px] m-auto '>
        test

      </div>

    </div>

    <div className='w-full max-w-[1400px] text-white'>

    </div>

  </div> 
  )
}
